import React, { useState } from 'react'
import { DropdownMenuList } from '../../../../../Components/Dropdown/menuList';
import { MappingTable } from './MappingTable';
import { ConfigureStyles } from './styles';
import Bidirectional from '../../../../../Assets/img/Directions/bidirectional.svg';
import Left from '../../../../../Assets/img/Directions/left.svg';
import Right from '../../../../../Assets/img/Directions/right.svg';
import { Button } from '../../../../../Components/Buttons';
import { styleTheme, styleThemeHS } from '../../../../../Styles/SelectTheme';
import ReactSelect from 'react-select';
import { capitalLetter } from '../../../../../Utils/String';
import { useGetPropertiesMutation } from '../../../../../Store/api/hubspot';
import { useEffect } from 'react';
import { AlertConfirm, AlertForm, AlertFormLarge, ICON } from '../../../../../Utils/Alert';

const ConfigureMapping = ({account, generalApp = {}, typeObject, setMappingData, mappingData ,setDirectionData, directionData}) => {

  const [selectOption, setSelectOption] = useState(Right);
  const [selectOptionsHS, setSelectOptionsHS] = useState([]);
  const [selectOptionsApp, setSelectOptionsApp] = useState([]);
  const [optionSelected, setOptionSelected] = useState({hs: "", app: "", direction: ""});

  const [customMapping, showCustomMapping] = useState(false);
  let [searchPropertiesHS, { isLoading: isLoadingHS, isSuccess: isSuccessHS, data: responseHS }] = useGetPropertiesMutation();
  const handleSelectSyncData = async (el) => {
    if(directionData === el) return;
    let result = await AlertConfirm({title: "When the address is modified, the synchronization address of the properties is updated.", text: "Do you want to continue?"})
    if(result.isConfirmed) {
      setSelectOption(el === "bidirectional" ? Bidirectional : el === "hs_app" ? Left : Right);
      setDirectionData(el);
      let cloneMapping = [...mappingData];
      for (const item of cloneMapping) {
        console.log({item});
        let findPropertyConfigApp = generalApp.properties[typeObject].find(prop => prop.name === item.app_property.id);
        let direction = item.direction || "app_hs";
        if(direction === "app_hs" && el !== "app_hs") item.active = false;
        else if(direction === "hs_app" && el !== "hs_app") item.active = false;
/*         else { 
          let validateDirection = item.direction === "bidirectional" ? "bidirectional" : item.direction;
          item.direction = validateDirection;
          item.temp.direction = validateDirection;
        } */
      }
      setMappingData(cloneMapping);
    }
  }

  useEffect(() => {
    if(generalApp.urls && generalApp.config && account) {
      searchPropertiesHS({
        body: {
          id_register: account.data.id,
          urlTokenRefresh: generalApp.urls.tokenRefresh,
          nameApp: generalApp.config?.app_name
        },
        object: typeObject === "order" ? "deal": typeObject
      })
      let filterOptions = [];
      for (const prop of generalApp.properties[typeObject]) {
        let findProp = mappingData.find(el => el.app_property.id === prop.name);
        if(!findProp) {
          filterOptions.push(prop);
        }
      }

      setSelectOptionsApp(filterOptions.map(el => {return {
        label: el.label,
        value: el.name,
        direction: el.direction
      }}))
    }
  }, [generalApp, account, mappingData])
    
  useEffect(() => {
    if(isSuccessHS){
      setSelectOptionsHS(responseHS.data.results.map(el => {return {
          label: el.label,
          value: el.name,
        }}))
    }
  }, [isSuccessHS])
  
  useEffect(() => {
    setSelectOption(directionData === "bidirectional" ? Bidirectional : directionData === "hs_app" ? Left : Right);
  }, [directionData])
  
  const handleCancelCustomProperty = () => {
    showCustomMapping(false);
    setOptionSelected({hs: "", app: "", direction: ""})
  }

  const handleNewCustomProperty = () => {
    if(optionSelected.direction && optionSelected.app.value && optionSelected.hs.value){
      showCustomMapping(false);
      let activeProperty;
      let directionProperty;

      if(directionData !== "bidirectional" && optionSelected.direction.value === "bidirectional") {
        activeProperty = true;
        directionProperty = directionData;
        AlertForm({title: "Synchronization direction is updated, because the general synchronization is in one direction."})
      } else if (directionData === "hs_app" && optionSelected.direction.value !== "hs_app"){
        activeProperty = false;
      } else if (directionData === "hs_app" && optionSelected.direction.value !== "hs_app"){
        activeProperty = false;
      } else {
        activeProperty = true;
      }
      setMappingData([...mappingData, {
        active: activeProperty,
        app_property: {
          id: optionSelected.app.value,
          label: optionSelected.app.label,
        },
        hs_property: {
          id: optionSelected.hs.value,
          label: optionSelected.hs.label,
        },
        type: "custom",
        direction: directionProperty || optionSelected.direction.value,
        temp: {
          app_property: {
            id: optionSelected.app.value,
            label: optionSelected.app.label,
          },
          hs_property: {
            id: optionSelected.hs.value,
            label: optionSelected.hs.label,
          },
          direction: optionSelected.direction.value,
        }
      }]);
      setOptionSelected({hs: "", app: "", direction: ""})
    } else {
      AlertForm({title: "Incomplete fields, validate information"})
    }
  }

  const handleNewCustomMapping = () => {
    let plan = account.data.ControlPlan.plan;
    let quantity = generalApp.dependencies.quantityMapping[typeObject][plan];
    console.log({quantity})
    if( quantity === "N/A" || (Number(quantity) > mappingData.length)) {
      showCustomMapping(true);
    } else {
      AlertFormLarge({
        title: "Limit reached for mapping", 
        text: "You must delete a mapping before you can set up a new one or update an existing mapping.", 
        icon: ICON.WARNING
      });
    }
    
  }
  return (
    <ConfigureStyles>
      <div className='sync-direction'>
        <h4>Sync direction</h4>
        <div className='section-sync'>
          <div className="grid">
              <div className='item-left'>
                <div>
                    <div className='image-app'>
                      <img src={generalApp.logo} alt="app" />
                    </div>
                    <div className='title-app'>
                        <span>{capitalLetter(generalApp.config?.app_name)} {capitalLetter(typeObject)}</span>
                    </div>
                </div>
              </div>
              <div className='item-center' >
                <DropdownMenuList img={selectOption} type="button-hs-secondary">
                  {generalApp.config?.sync[typeObject].map((el, idx) => {
                    return <div className="item" onClick={() => handleSelectSyncData(el)} key={idx}>
                    <img src={el === "bidirectional" ? Bidirectional : el === "hs_app" ? Left : Right} alt="direction" />
                    <p>{el === "bidirectional" ? "Data sync between apps": el === "hs_app" ? `Data syncs only to ${capitalLetter(generalApp.config?.app_name)}` : "Data syncs only to HubSpot"}</p>
                  </div>
                  })}
                </DropdownMenuList>
              </div>
              <div className='item-right'>
              <div>
                    <div className='image-app'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 512 512"><path fill="#f97a5a" d="M267.4 211.6c-25.1 23.7-40.8 57.3-40.8 94.6c0 29.3 9.7 56.3 26 78L203.1 434c-4.4-1.6-9.1-2.5-14-2.5c-10.8 0-20.9 4.2-28.5 11.8s-11.8 17.8-11.8 28.6s4.2 20.9 11.8 28.5s17.8 11.6 28.5 11.6c10.8 0 20.9-3.9 28.6-11.6c7.6-7.6 11.8-17.8 11.8-28.5c0-4.2-.6-8.2-1.9-12.1l50-50.2c22 16.9 49.4 26.9 79.3 26.9c71.9 0 130-58.3 130-130.2c0-65.2-47.7-119.2-110.2-128.7V116c17.5-7.4 28.2-23.8 28.2-42.9c0-26.1-20.9-47.9-47-47.9S311.2 47 311.2 73.1c0 19.1 10.7 35.5 28.2 42.9v61.2c-15.2 2.1-29.6 6.7-42.7 13.6c-27.6-20.9-117.5-85.7-168.9-124.8c1.2-4.4 2-9 2-13.8C129.8 23.4 106.3 0 77.4 0C48.6 0 25.2 23.4 25.2 52.2c0 28.9 23.4 52.3 52.2 52.3c9.8 0 18.9-2.9 26.8-7.6zm89.5 163.6c-38.1 0-69-30.9-69-69s30.9-69 69-69s69 30.9 69 69s-30.9 69-69 69"/></svg>
                    </div>
                    <div className='title-app'>
                        <span>HubSpot {capitalLetter(typeObject)}</span>
                    </div>
                </div>
              </div>
          </div>
        </div>
        {generalApp.config?.msgSyncDirection[typeObject] && <p><b>Important:</b> {generalApp.config.msgSyncDirection[typeObject]} </p>}
      </div>
      <div className="mapping">
        <h4>Mapped fields</h4>
        <p>Field mappings connect individual fields with matching information from each app. You can turn off the default mappings we’ve created for you, or create new custom mappings that are unique to your business. <a href="https://knowledge.hubspot.com/integrations/understand-your-data-sync-field-mappings">Learn more</a> </p>
        <MappingTable 
          typeObject={typeObject} 
          setMappingData={setMappingData} 
          mappingData={mappingData}
          selectOptionsApp={selectOptionsApp} 
          selectOptionsHS={selectOptionsHS} 
          setDirectionData={setDirectionData}
          directionData={directionData}
          nameApp={generalApp.name}
        />
        <section className='custom-mapping'>
          {!customMapping && <Button className={"hubspot"} bgColor={"#425b76"} color="white" onPress={handleNewCustomMapping}>Custom Mappings</Button>}
          {customMapping && <div className='section-custom'>
            <div className='section-custom-inner'>
              <div className='select'>
              <ReactSelect
                options={selectOptionsApp}
                theme={(theme) => styleThemeHS(theme)}
                onChange={(e) => setOptionSelected({...optionSelected, app: e, direction: ""})}
                value={optionSelected.app}
                menuPlacement="top"
              />
              </div>
              <div className='select small'>
              <ReactSelect
                options={optionSelected.app.direction === "bidirectional" ?[
                  {
                    label: '→',
                    value: "app_hs",
                  },
                  {
                    label: '←',
                    value: "hs_app",
                  },
                  {
                    label: '⇌',
                    value: "bidirectional",
                  },
                ]:optionSelected.app.direction === "hs_app" ?[
                  {
                    label: '←',
                    value: "hs_app",
                  },
                ]: [
                  {
                    label: '→',
                    value: "app_hs",
                  },
                ]}
                theme={(theme) => styleThemeHS(theme)}
                onChange={(e) => setOptionSelected({...optionSelected, direction: e})}
                value={optionSelected.direction}
                menuPlacement="top"
                placeholder=""
              />
              </div>
              <div className='select'>
                <ReactSelect
                  options={selectOptionsHS}
                  theme={(theme) => styleThemeHS(theme)}
                  onChange={(e) => setOptionSelected({...optionSelected, hs: e})}
                  value={optionSelected.hs}
                  menuPlacement="top"
                />
              </div>
              <div className='actions'>
                <Button className={"hubspot button--sm"} bgColor={"#425b76"} color="white" bgColorHover={"#425b76"} onPress={handleNewCustomProperty}>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" viewBox="0 0 36 36"><path fill="white" d="M13.72 27.69L3.29 17.27a1 1 0 0 1 1.41-1.41l9 9L31.29 7.29A1 1 0 0 1 32.7 8.7Z" className="clr-i-outline clr-i-outline-path-1"/><path fill="none" d="M0 0h36v36H0z"/></svg>
                    </div>
                </Button>
                <Button className={"hubspot button--sm"} onPress={handleCancelCustomProperty}>
                    <div style={{display: "flex", alignItems: "center"}} >
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 16 16"><path fill="currentColor" d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/></svg></span>
                    </div>
                </Button>
              </div>
            </div>
          </div>}
        </section>
      </div>
    </ConfigureStyles>
  )
}

export default ConfigureMapping;