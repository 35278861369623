import React, { useEffect, useState, useMemo } from 'react';
import 'react-tabs/style/react-tabs.css';
import { Button } from '../../../../Components/Buttons';
import { LinkStyles, StateStyle } from '../../../../Components/Table/HubSpot/styles';
import { TableHubSpot } from '../../../../Components/Table/HubSpot/tableHubSpot';
import { useGetObjectsMutation } from '../../../../Store/api/apps';
import { useGetTransactionsByClientQuery } from '../../../../Store/api/count';
import { AlertClose, AlertLoading } from '../../../../Utils/Alert';
import { getObjectsQueryUrl } from '../../../../Utils/QueryUrl';
import { capitalLetter } from '../../../../Utils/String';
import { formatDate } from '../../../../Utils/times';


export const RecordView = ({account, generalApp}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [idClient, setIdClient] = useState(1);
  let [searchObjectFetch, { isLoading: isLoadingGetObjects, isSuccess: isSuccessObjects, data: responseObjects }] = useGetObjectsMutation();
  let { isLoading: isLoadingTransaction, isSuccess: isSuccessTransaction, data: responseData, refetch: refetchTransaction  } = useGetTransactionsByClientQuery(idClient);
  
  useEffect(() => {
    let objQuery = getObjectsQueryUrl(window.location.href);
    searchObjectFetch({
      accountId: objQuery.accountId,
      appId: objQuery.appId,
      portalId: objQuery.portalId
    })
    let idClientIntegrator = account.data.AppsSetting?.idClientIntegrador || account.data.VtexSetting?.id_client_integrador || account.data.ShopifySetting?.id_client_integrador
    setIdClient(idClientIntegrator)
  }, [account])
  
  useEffect(() => {
    if(isSuccessTransaction){
      transactionRequest();
    }
  }, [isSuccessTransaction, responseData])

  
  const columns = useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        cell: ({ row, getValue }) => {
          return <>
              {
                row.original.url ? <LinkStyles onClick={() =>handleOpenWindow(row.original.url)}>
                {getValue()} <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14"><path fill="#9a9a9a" fillRule="evenodd" d="M7 2H2.5a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7a1 1 0 1 1 2 0v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 0 11.5v-9A2.5 2.5 0 0 1 2.5 0H7a1 1 0 0 1 0 2M9.538.309A.5.5 0 0 1 10 0h3.5a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-.854.354L12.104 3.31L7.707 7.707a1 1 0 0 1-1.414-1.414l4.396-4.397L9.646.854a.5.5 0 0 1-.108-.545" clipRule="evenodd"/></svg>
              </LinkStyles>: <span>{getValue() || "No info"}</span>
              }
              
              <div>
                {
                  row.original.email ?? <span>{row.original.email}</span>
                }
              </div>         
            </>
        },
      },
      {
        accessorFn: row => row.object,
        id: 'object',
        cell: info => capitalLetter(info.getValue()),
        header: () => <span>Object</span>,
        options:  responseObjects?.data?.objects?.map(el => {return {id: el.id, label: el.name}}) || [],
        meta: {
          filterVariant: 'select',
        },
        size: 120,
      },
      {
        id: 'status',
        accessorKey: 'status',
        cell: ({ row, getValue }) => {
          return <>
               <StateStyle className={getValue() === "inSync" ? "on": "error"}></StateStyle>
                <span>{capitalLetter(getValue()) || "No Info"}</span>     
            </>
        },
        options: [{id: "inSync", label: "InSync"}, {id: "failing", label: "Failing"}],
        meta: {
          filterVariant: 'select',
        },
        size: 120,
      },
      {
        id: 'activityDate',
        accessorFn: row => Date.parse(row.createdAt),
        header: () => 'Activity Date',
        cell: info => formatDate(info.getValue()),
        meta: {
          filterVariant: 'date',
        },
        size: 250,
      },
      {
        id: 'observation',
        accessorKey: 'nameSync',
        header: () => <span>Sync Activity</span>,
        cell: ({ row, getValue }) => {
          return row.getCanExpand() ? (
            <>
              <div className='expand-section'>
                <button className='btn-expand'
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' },
                  }}
                >
                  {row.getIsExpanded() ? 
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 24 24"><path fill="var(--primary-hubspot-color)" d="M16.939 7.939L12 12.879l-4.939-4.94l-2.122 2.122L12 17.121l7.061-7.06z"/></svg>
                    <span>{getValue() || "View Details"}</span>
                  </> : 
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 24 24"><path fill="var(--primary-hubspot-color)" d="M10.061 19.061L17.121 12l-7.06-7.061l-2.122 2.122L12.879 12l-4.94 4.939z"/></svg>
                    <span>{getValue() || "View Details"}</span>
                  </>
                  }

                </button>
              </div>      
            </>
          ) : (
            <>
              <span>{getValue()}</span>
            </>
          )
        },
        meta: {
          filterVariant: 'hidden',
        },
      },
      {
        id: 'source',
        accessorKey: 'source',
        header: () => <span>Source</span>,
        cell: info => info.getValue(),
        options: [{id: "hubSpot", label: "HubSpot"}, {id: generalApp.config.app_name, label:  capitalLetter(generalApp.config.app_name)}],
        meta: {
          filterVariant: 'select',
        },
        size: 120,
      },
    ],
    [responseObjects]
  )
  const transactionRequest = () => {
    let categoriesApp = generalApp.config.categoryObject;
    let objCategories = {};
    for (const key in categoriesApp) {
      if (Object.hasOwnProperty.call(categoriesApp, key)) {
        const arrayCategories = categoriesApp[key];
        for (const item of arrayCategories) {
          objCategories[item] = key;
        }
      }
    }
    let dataTableRecords = [];
    for (const item of responseData.data) {
      dataTableRecords.push({
        ...item,
        object: objCategories[item.category]
      })
    }
    AlertClose()
    setData(dataTableRecords);
  }
  const handleOpenWindow = (url) => {
    if(!url) return;
    window.open(url, "_blank");
  }

  const handleSyncNow = () => {
    refetchTransaction();
    //AlertLoading({title: "Cargando"});
  }
  return (
   <>
    <h2 className='h2'>Record View</h2>
    <section>
      <div className='section-btn'>
        <Button className={"hubspot button--sm"} onPress={() => setShowFilter(!showFilter)}>{showFilter ? "Hidden" : "Show"} filters</Button>
        <Button className={"hubspot button--sm"} onPress={handleSyncNow} >Refresh</Button>

      </div>
      <div>
        <TableHubSpot dataTable={data} showFilter={showFilter} columns={columns} rowExpand={true} subComponent={RenderSubComponent}/>
      </div>
    </section>
   </>
  );
};


const RenderSubComponent = ({row}) => {
  return <section>
    <h4>Details:</h4>
    <p>{row.original.status === "inSync" ? "Data synchronized correctly: " + row.original.observation : row.original.observation || "No error data"}</p>
    {row.original.status !== "inSync" ? <p style={{marginTop: "1rem"}}><b> Data Workflow:</b> {row.original.id_workflow}</p>: ""}
  </section>
}